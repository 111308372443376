import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Trainerwerk = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Trainerwerk" />
      <StyledHero img={data.trainerwerkBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="Trainerwerk" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="http://trainerwerk.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/trainerwerk.png"
                alt="Trainerwerk Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot Trainerwerk</h4>
          </header>
          <article className={styles.offerText}>
            <h4>Führen in turbulenten Zeiten</h4>
            <p>mit Claudia Amend</p>
            <p>Wöchentlich, montags, 14-15 Uhr</p>
          </article>
          <article className={styles.offerText}>
            <h4>Zusammenarbeit in Teams trotz "Social Distancing"</h4>
            <p>mit Sandra Khalil</p>
            <p>Wöchentlich, mittwochs 16-17 Uhr</p>
          </article>
          <article className={styles.offerText}>
            <h4>Cool down im Homeoffice</h4>
            <p>mit Tilman Bessler</p>
            <p>Wöchentlich, donnerstags 16-17 Uhr</p>
          </article>
          <footer>
            <a
              href="mailto:webkonferenz@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Hier anmelden
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    trainerwerkBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Trainerwerk
